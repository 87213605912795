/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type BasicselectcardrequestType =
  (typeof BasicselectcardrequestType)[keyof typeof BasicselectcardrequestType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BasicselectcardrequestType = {
  multi_select: 'multi_select',
  circle_single_select: 'circle_single_select',
} as const
