/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type TextorheadingparagraphresponseType =
  (typeof TextorheadingparagraphresponseType)[keyof typeof TextorheadingparagraphresponseType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TextorheadingparagraphresponseType = {
  text: 'text',
  heading: 'heading',
} as const
