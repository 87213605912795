/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * forerunnerApi
 * OpenAPI spec version: 1.0
 */

export type TextorheadingparagraphrequestType =
  (typeof TextorheadingparagraphrequestType)[keyof typeof TextorheadingparagraphrequestType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TextorheadingparagraphrequestType = {
  text: 'text',
  heading: 'heading',
} as const
